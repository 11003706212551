@import '../Base.module.scss';

.login{
    padding-top: 35px;
    max-width: 900px;
    margin: 0 auto;

    a {
        display: block;
        margin-top: 8px;
        font-weight: 800;
        text-align: center;
    }

    .btnLogin {
        height: 42px;
        width: 100%
    }

    .whiteLine {
        margin-top: 32px;
        margin-bottom: 24px;
        border: 1px solid $fk-white;
    }

    div.paddedRow {
        margin-top: 15px;
    }
}