@import '../Base.module.scss';

.copyRow {
    @include ms-Grid-row;
    padding-top: 50px;
    padding-bottom: 24px;
    font-size: 14px;

    .copyCol {
        @include ms-Grid-col;
        @include ms-sm12;
        float: left;
    }
}

:global { 
    .login {
        div {
            @media (min-width: $ms-screen-min-xl) {
                margin-left: 16.666666666666664%;
                width: 75% !important;
            }
        }
    }

    .change {
        div {
            @media (min-width: $ms-screen-min-xl) {
                margin-left: 25%;
                width: 66.66666666666666% !important;
            }
        }
    }
}
