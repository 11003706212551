@import '../../node_modules/@fluentui/react/dist/sass/References';

//color variables
$fk-red: #ec1c24;
$fk-blue: #0063be;
$fk-grey: #efefef;
$fk-dark-grey: #a5a5a5;
$fk-white: #fff;

:export { 
    fkRed: $fk-red;
    fkBlue: $fk-blue;
    fkGrey: $fk-grey;
    fkDarkGrey: $fk-dark-grey; 
    fkWhite: $fk-white; 
}

html {
    font-family: Verdana, sans-serif;
    font-size:14px;
}

h1 {
    font-size: 1.5rem;
    margin-block-start: 0.1em;
}

h2 {
    font-size: 1.3rem;
    margin-block-start: 0.1em;
}

a {
    text-decoration: none;
}

a, .link {
    cursor: pointer;
    color: $fk-blue;
    display: inline;
}

.labelBox {
    width: 100%;
    display: table;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.red {
    color: $fk-red;
}

.error {
    font-size: 0.7rem;
    color: $fk-red;
}

.inline {
    display: inline;
}

.bgg {
    background-color: $fk-grey;
}

.bgw {
    background-color: $fk-white;
}

.bgdg {
    background-color: $fk-dark-grey;
    color: $fk-white;
}

.row {
    @include ms-Grid-row;
    padding-top: 8px;
    padding-bottom: 8px;
}

.paddedRow {
    @include ms-Grid-row;
    margin-top: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.unpaddedRow {
    @include ms-Grid-row;
}

.col {
    @include ms-Grid-col;
    float: left;
}

.sm1 {
    @include ms-sm1;
}

.sm2 {
    @include ms-sm2;
}

.sm3 {
    @include ms-sm3;
}

.sm4 {
    @include ms-sm4;
}

.sm5 {
    @include ms-sm5;
}

.sm6 {
    @include ms-sm6;
}

.sm7 {
    @include ms-sm7;
}

.sm8 {
    @include ms-sm8;
}

.sm9 {
    @include ms-sm9;
}

.sm10 {
    @include ms-sm10;
}

.sm11 {
    @include ms-sm11;
}

.sm12 {
    @include ms-sm12;
}

.md1 {
    @include ms-md1;
}

.md2 {
    @include ms-md2;
}

.md3 {
    @include ms-md2;
}

.md4 {
    @include ms-md4;
}

.md6 {
    @include ms-md6;
}

.md7 {
    @include ms-md7;
}

.md9 {
    @include ms-md9;
}

.md10 {
    @include ms-md10;
}

.lg1 {
    @include ms-lg1;
}

.lg2 {
    @include ms-lg2;
}

.lg3 {
    @include ms-lg3;
}

.lg4 {
    @include ms-lg4;
}

.lg5 {
    @include ms-lg5;
}

.lg6 {
    @include ms-lg6;
}

.lg7 {
    @include ms-lg7;
}

.lg8 {
    @include ms-lg8;
}

.lg9 {
    @include ms-lg9;
}

.lg10 {
    @include ms-lg10;
}

.lg11 {
    @include ms-lg11;
}

.lg12 {
    @include ms-lg12;
}

.xl2 {
    @include ms-xl2;
}

.xl3 {
    @include ms-xl3;
}

.xl7 {
    @include ms-xl7;
}

.xl6 {
    @include ms-xl6;
}

.xl8 {
    @include ms-xl8;
}

.xl9 {
    @include ms-xl9;
}

.xl10 {
    @include ms-xl10;
}

.xxl2 {
    @include ms-xxl2;
}
    
.smOffset1 {
    margin-left: 8.333333333333332%;
}
    
.smOffset3 {
    margin-left: 25%;
}
    
.smOffset4 {
    margin-left: 33.333333333333332%;
}
    
.smOffset6 {
    margin-left: 50%;
}
    
.smOffset7 {
    margin-left: 58.333333333333336%;
}
    
.smOffset8 {
    margin-left: 66.66666666666666%;
}

@media (min-width: $ms-screen-min-md) {
    .mdRight {
        text-align: right;
    }
    
    .mdOffset2 {
        margin-left: 16.666666666666664%;
    }
}

@media (min-width: $ms-screen-min-lg) {
    html {
        font-size: 17px;
    }
    
    .labelBox {
        height: 48px;
    }

    .lgRight {
        text-align: right;
    }
    
    .lgOffset1 {
        margin-left: 8.333333333333332%;
    }
    
    .lgOffset2 {
        margin-left: 16.666666666666664%;
    }
    
    .lgOffset3 {
        margin-left: 25%;
    }
    
    .lgOffset4 {
        margin-left: 33.333333333333332%;
    }
    
    .lgOffset6 {
        margin-left: 50%;
    }
    
    .lgOffset5 {
        margin-left: 41.666666666666664%;
    }
    
    .lgOffset7 {
        margin-left: 58.333333333333336%;
    }
    
    .lgOffset8 {
        margin-left: 66.66666666666666%;
    }
    
    .lgOffset9 {
        margin-left: 75%;
    }
    
    .lgOffset10 {
        margin-left: 83.333333333333332%;
    }
}

@media (min-width: $ms-screen-min-xl) {

    .xlRight {
        text-align: right;
    }
    
    .xlOffset0 {
        margin-left: 0;
    }
    
    .xlOffset1 {
        margin-left: 8.333333333333332%;
    }
    
    .xlOffset2 {
        margin-left: 16.666666666666664%;
    }
    
    .xlOffset3 {
        margin-left: 25%;
    }
}

@media print {

    body {
      margin: 0;
      -webkit-print-color-adjust: exact;
    }

    .noPrint {
        display: none;
    } 

    .printFullWidth {
        display: block;
        width: 100% !important;
        margin: 0 !important;
    }

    h1 {
        text-align: center;
    }

    .lg4 {
        width: 33%;
        display: inline;
    }

    #location_0 {
        background-color: red !important;
    }
  }