@import '../Base.module.scss';

.reportDetail{
    h3 {
        padding: 0 10px;
        margin-bottom: 0;
    }
    
    .details {
        font-size: 0.8rem;
    }
    
    .detailsTable {
        display: table;
    }
    
    .detailsRow {
        display: table-row;
    }
    
    .detailsCell {
        padding: 0 10px;
        display: table-cell;
    }

    @keyframes blink{
        0% {
            color: red;
            border: 3px solid green;
        }
        20% {
            color: green;
            border: 3px solid red;
        }
        40% {
            color: red;
            border: 3px solid green;
        }
        60% {
            color: green;
            border: 3px solid red;
        }
        80% {
            color: red;
            border: 3px solid green;
        }
        100% {
            color: green;
            border: 3px solid red;
        }
      }

    .survey { 
        animation: blink 5s infinite;
        font-size: 1.2rem;
        font-weight: 800;
        padding: 7px;
    }
}