@import '../../node_modules/@fluentui/react/dist/sass/References';
@import './Base.module.scss';

body {
    margin: 8px 0;
}

.appContainer {
    @include ms-Grid;
    background-color: $fk-grey;
    max-width: 1600px;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}