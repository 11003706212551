@import '../../../scss/components/Form.module.scss';

.imageContainer {
    position:relative;
    width: 100%;

    img {
        width: 100%;
    }

    .location {
        position: absolute;
        //background-color: rgba(255, 0, 0, 0.2);
    }

    .location:hover {
        background-color: rgba(255, 0, 0, 0.2);
    }

    .selected {
        background-color: rgba(255, 0, 0, 0.2);
    }
}
