@import '../Base.module.scss';
@import '../../../node_modules/@fluentui/react/dist/sass/References';

.compactRow {
    @include ms-Grid-row;
    padding-top: 2px;
    padding-bottom: 2px;
}

.compactLabelBox {
    width: 100%;
    height: 32px;
    display: table;
}