@import '../Base.module.scss';
@import '../../../node_modules/@fluentui/react/dist/sass/References';

.paginator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .separator {
        min-width: 26px;
    }
}

