@import '../Base.module.scss';

.aside {
    font-size: 0.7rem;
}

.table {
    display: inline-table;
}

.cell {
    display: table-cell;
    vertical-align: middle;
}