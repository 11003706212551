@import './Base.module.scss';

.table {
    display: table;
    width: 100%;
}

.tableRow {
    display: table-row;
    width: 100%;
}

.headerRow {
    background-color: $fk-blue;
    color: $fk-white;
}

.tableCell {
    display: table-cell;
    font-size: 12px;
    padding: 5px;
    background-color: $fk-white;
    border: 1px solid $fk-grey;
}

.headerCell {
    background-color: $fk-blue;
    color: $fk-white;
}

.editCell {
    width: 16px;
    border: none;
    background-color: $fk-grey;
}

.createNew {
    font-size: 2rem;
}